import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import {
  Modal,
  Button,
  Header,
  Icon,
  Radio,
  Form,
  Label,
  Table,
  Dropdown,
  Input,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import {
  tonghopsk,
  theodoisuckhoe,
  theodoisdd,
  tonghopchatluongmau1,
  tonghopchatluongmau2,
} from "./thongkeReport";
import { db } from "../../../libs/firebase";
import { convertTimeStamp } from "../../../libs/time";
import { readDoTuoi } from "./helpers";

const monthsOfYear = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "1",
];

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
      useDay: false,
      gradesList: {},
      month: null,
      eduQualityList: {},
      checkAllClasses: false,
      monthsOfMamChoiLa: ["9", "1", "5"],
    };
  }

  componentWillMount() {
    const allMonthData = this.getAllMthData();
    if (!this.state.allMonthData) this.setState({ allMonthData });
  }

  getAllMthData = () => {
    const { classes } = this.props;
    const res = {
      8: { order: 1, mth: 8 },
      9: { order: 2, mth: 9 },
      10: { order: 3, mth: 10 },
      11: { order: 4, mth: 11 },
      12: { order: 5, mth: 12 },
      1: { order: 6, mth: 1 },
      2: { order: 7, mth: 2 },
      3: { order: 8, mth: 3 },
      4: { order: 9, mth: 4 },
      5: { order: 10, mth: 5 },
      6: { order: 11, mth: 6 },
      7: { order: 12, mth: 7 },
    };
    const {
      unit: { unitID, activeYear },
    } = this.props;
    const { monthsStatus } = this.props;
    _.forEach(monthsOfYear, (month) => {
      _.forEach(classes, ({ classID, name, teachers, grade }) => {
        db.ref(`cando/${unitID}/${activeYear}/${classID}/months/${month}`).once(
          "value",
          (snapshot) => {
            if (snapshot.val()) {
              _.forEach(teachers, (teacherEmail, idx) => {
                db.ref("users").once("value", (snap) => {
                  if (_.find(snap.val(), ({ email }) => email === teacherEmail))
                    _.set(
                      res[month],
                      `${classID}.teachers.${idx}`,
                      _.find(snap.val(), ({ email }) => email === teacherEmail)
                    );
                });
              });
              _.set(res[month], `${classID}.students`, snapshot.val());
              _.set(res[month], `${classID}.name`, name);
              _.set(res[month], `${classID}.grade`, grade);
              if (monthsStatus[classID])
                _.set(
                  res[month],
                  `${classID}.day`,
                  _.get(monthsStatus[classID][month], "day", 0)
                );
            }
          },
          (err) => console.log(err)
        );
        db.ref(
          `thongke/${unitID}/${activeYear}/${classID}/months/${month}`
        ).once(
          "value",
          (snapshot) => {
            if (snapshot.val()) {
              _.set(res[month], `${classID}.eduQuality`, snapshot.val());
            }
          },
          (error) => console.log(error)
        );
      });
    });
    return res;
  };

  render() {
    const modalName = {
      tdsk: "Theo dõi sức khỏe trẻ",
      thsk: "Tổng hợp sức khỏe toàn trường",
      tdsdd: "Theo dõi suy dinh dưỡng",
      clgd: "Tổng hợp chất lượng CSGD",
    };
    const monthsOptions = _.map(
      [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7],
      (idx) => ({
        key: idx,
        text: idx,
        value: idx,
      })
    );
    const {
      closeHandler,
      classes,
      solieucando,
      generalType,
      errorHandler,
      successHandler,
      monthsStatus,
      unit: {
        activeYear,
        information: { name: unitName, sgd },
      },
    } = this.props;
    const {
      useDay,
      gradesList,
      month,
      eduQualityList,
      checkAllClasses,
      isExporting,
      allMonthData,
      monthsOfMamChoiLa,
    } = this.state;

    const tonghopchatluong =
      sgd !== "lamdong" ? tonghopchatluongmau1 : tonghopchatluongmau2;

    const getStatus = (classID, month) => {
      if (_.get(monthsStatus, `${classID}.${month}`))
        return `${_.get(
          monthsStatus,
          `${classID}.${month}.modifiedBy`
        )}. ${convertTimeStamp(
          _.get(monthsStatus, `${classID}.${month}.modifiedTime`)
        )}`;
      return "Chưa có số liệu cân đo";
    };
    const gradesListArr = _.filter(
      _.map(gradesList, (selected, key) => {
        const info = _.find(classes, ({ classID }) => classID === key);
        return {
          ...info,
          selected,
        };
      }),
      ({ selected }) => selected
    );
    const onComplete = () => {
      this.setState({ isExporting: false });
    };
    const Checkbox = ({
      type = "checkbox",
      name,
      checked = false,
      onChange,
      label = "Chọn",
      header = false,
    }) => (
      <div className="xuathongkeCheckbox">
        <input
          type={type}
          name={name}
          checked={checked}
          onChange={onChange}
          style={{ marginRight: "10px" }}
        />
        <Label className={header ? "checkboxLabel" : ""}>{label}</Label>
      </div>
    );
    const checkAllHandler = () => {
      const allClasses = {};
      _.forEach(classes, ({ classID }) => {
        _.set(allClasses, classID, !checkAllClasses);
      });
      this.setState((prevState) => ({
        checkAllClasses: !prevState.checkAllClasses,
        gradesList: allClasses,
      }));
    };
    const validateNumber = (value) => {
      if (isNaN(value)) {
        errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
        return undefined;
      }
      return value;
    };
    const exportHandler = (all = false) => {
      this.setState({ isExporting: true });
      switch (generalType) {
        case "thsk":
          tonghopsk(
            allMonthData,
            activeYear,
            solieucando,
            unitName,
            useDay,
            errorHandler,
            closeHandler,
            onComplete,
            sgd
          );
          break;
        case "tdsk":
          theodoisuckhoe(
            allMonthData,
            activeYear,
            solieucando,
            unitName,
            useDay,
            gradesListArr,
            errorHandler,
            closeHandler,
            onComplete,
            sgd,
            monthsOfMamChoiLa
          );
          break;
        case "tdsdd":
          theodoisdd(
            allMonthData,
            activeYear,
            solieucando,
            unitName,
            useDay,
            !all
              ? gradesListArr
              : [{ name: "Toàn trường", grade: ["all"], classID: "all" }],
            all,
            errorHandler,
            closeHandler,
            onComplete,
            sgd
          );
          break;
        case "clgd":
          tonghopchatluong(
            allMonthData,
            activeYear,
            solieucando,
            unitName,
            useDay,
            eduQualityList,
            month,
            errorHandler,
            closeHandler,
            onComplete,
            sgd
          );
          break;
        default:
          break;
      }
    };
    const saveEduQualityHandler = () => {
      const succed = [];
      _.forEach(eduQualityList, (data, classId) => {
        db.ref(
          `thongke/${this.props.unit.unitID}/${activeYear}/${classId}/months/${month}`
        ).set(
          {
            ...data,
          },
          (err) => {
            if (err) succed.push(false);
            else succed.push(true);
          }
        );
      });
      if (_.every(succed, (val) => val)) successHandler("Cập nhật thành công!");
      else errorHandler("Cập nhật không thành công, vui lòng thử lại!");
    };
    const renderButtons = () => {
      const { allowEdit } = this.props;
      return (
        <>
          {generalType === "thsk" ? (
            <Button
              color="orange"
              size="small"
              onClick={() => {
                exportHandler();
              }}
            >
              <Icon name="file excel outline" />
              Xuất Excell
            </Button>
          ) : null}
          {generalType === "tdsk" ? (
            <Button
              color="orange"
              size="small"
              onClick={() => {
                exportHandler();
              }}
              disabled={!gradesListArr.length || gradesListArr.length > 20}
            >
              <Icon name="file excel outline" />
              Xuất Excell
            </Button>
          ) : null}
          {generalType === "tdsdd" ? (
            <>
              <Button
                color="orange"
                size="small"
                onClick={() => {
                  exportHandler();
                }}
                disabled={!gradesListArr.length || gradesListArr.length > 20}
              >
                <Icon name="file excel outline" />
                Xuất theo lớp
              </Button>
              {allowEdit ? (
                <Button
                  color="brown"
                  size="small"
                  onClick={() => {
                    exportHandler(true);
                    this.setState({ gradesList: "all" });
                  }}
                >
                  <Icon name="file excel outline" />
                  Xuất toàn trường
                </Button>
              ) : null}
            </>
          ) : null}
          {generalType === "clgd" ? (
            <Button
              color="green"
              size="small"
              onClick={() => {
                exportHandler();
                // saveEduQualityHandler();
              }}
              disabled={
                !_.some(eduQualityList, (obj) => {
                  if (obj) {
                    const { soBeCham, ...rest } = obj;
                    if (
                      _.keys(rest).length === 3 &&
                      _.every(rest, (val) => val !== "")
                    )
                      return true;
                    return false;
                  }
                })
              }
            >
              <Icon name="file excel outline" />
              Lưu & xuất file
            </Button>
          ) : null}
        </>
      );
    };
    const renderTheoDoiSucKhoe = () => {
      return (
        <>
          <p
            style={{
              color: "red",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span>* Chỉ có thể chọn tối đa 20 lớp </span>
            <span>
              {` (Đã chọn: ${_.filter(gradesList, (val) => val).length}/20)`}
            </span>
          </p>
          <Table
            style={{ background: "transparent" }}
            celled
            striped
            color="brown"
            unstackable
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tên lớp
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Khối
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  <Checkbox
                    label="Chọn tất cả"
                    checked={checkAllClasses}
                    onChange={() => checkAllHandler()}
                    header
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(classes, ({ grade, classID, name }) => (
                <Table.Row key={`${classID}`}>
                  <Table.Cell collapsing>{name}</Table.Cell>
                  <Table.Cell collapsing>
                    {_.join(
                      grade.map((dt) => readDoTuoi(dt)),
                      ", "
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Checkbox
                      name={name}
                      checked={gradesList[classID]}
                      onChange={() => {
                        this.setState((prevState) => ({
                          gradesList: {
                            ...prevState.gradesList,
                            [classID]: !prevState.gradesList[classID],
                          },
                          checkAllClasses: false,
                        }));
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      );
    };
    const renderTongHopSucKhoe = () => {
      return (
        <>
          <Table
            style={{ background: "transparent" }}
            celled
            striped
            color="brown"
            unstackable
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tên lớp
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Khối
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(classes, ({ grade, classID, name }) => (
                <Table.Row key={`${classID}`}>
                  <Table.Cell collapsing>{name}</Table.Cell>
                  <Table.Cell collapsing>
                    {_.join(
                      grade.map((dt) => readDoTuoi(dt)),
                      ", "
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      );
    };
    const renderTheoDoiSDD = () => {
      return (
        <>
          <p
            style={{
              color: "red",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span>* Chỉ có thể chọn tối đa 20 lớp </span>
            <span>
              {` (Đã chọn: ${_.filter(gradesList, (val) => val).length}/20)`}
            </span>
          </p>
          <Table
            style={{ background: "transparent" }}
            celled
            striped
            color="brown"
            unstackable
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tên lớp
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Khối
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  <Checkbox
                    label="Chọn tất cả"
                    checked={checkAllClasses}
                    onChange={() => checkAllHandler()}
                    header
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(classes, ({ grade, classID, name }) => (
                <Table.Row key={`${classID}`}>
                  <Table.Cell collapsing>{name}</Table.Cell>
                  <Table.Cell collapsing>
                    {_.join(
                      grade.map((dt) => readDoTuoi(dt)),
                      ", "
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Checkbox
                      name={name}
                      checked={gradesList[classID]}
                      onChange={() => {
                        this.setState((prevState) => ({
                          gradesList: {
                            ...prevState.gradesList,
                            [classID]: !prevState.gradesList[classID],
                          },
                          checkAllClasses: false,
                        }));
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      );
    };
    // LẤY GIÁ TRỊ ĐÁNH GIÁ BÉ KHỎE, BÉ NGOAN
    const handleMonth = (viewedMonth) => {
      const res = {};
      _.forEach(classes, ({ classID }) => {
        const monthEduQuality = _.get(
          allMonthData,
          `${viewedMonth}.${classID}.eduQuality`
        );
        _.set(res, `${classID}`, monthEduQuality);
      });
      this.setState({ eduQualityList: res });
    };
    const renderEduQuality = () => {
      const eduQualityHandler = (classID, value, type) => {
        const verified = validateNumber(value);
        if (verified !== undefined)
          this.setState((prevState) => ({
            eduQualityList: {
              ...prevState.eduQualityList,
              [classID]: {
                ...prevState.eduQualityList[classID],
                [type]: value,
              },
            },
          }));
      };
      return (
        <>
          <Table
            style={{ background: "transparent" }}
            celled
            striped
            color="brown"
            unstackable
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tên lớp
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Khối
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Số lượng bé chăm
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tỷ lệ bé chăm
                  <span>
                    <Icon name="percent" />
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Số lượng bé khỏe ngoan
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tỷ lệ bé khỏe ngoan
                  <span>
                    <Icon name="percent" />
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Trạng thái
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(classes, (classInfo) => {
                const { grade, classID, name } = classInfo;
                return (
                  <Table.Row key={classID}>
                    <Table.Cell collapsing>{name}</Table.Cell>
                    <Table.Cell collapsing>
                      {_.join(
                        grade.map((dt) => readDoTuoi(dt)),
                        ", "
                      )}
                    </Table.Cell>
                    {_.map(
                      ["soBeCham", "tyLeBeCham", "soBeNgoan", "tyLeBeNgoan"],
                      (keyVal) => (
                        <Table.Cell collapsing key={keyVal}>
                          <Input
                            fluid
                            onChange={(e, { value }) =>
                              eduQualityHandler(classID, value, keyVal)
                            }
                            value={_.get(
                              eduQualityList,
                              `${classID}.${keyVal}`,
                              ""
                            )}
                          />
                        </Table.Cell>
                      )
                    )}
                    <Table.Cell collapsing>
                      {getStatus(classID, month)}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      );
    };
    if (isExporting) {
      return (
        <Dimmer active>
          <Loader>Đang xử lí...</Loader>
        </Dimmer>
      );
    }
    if (gradesListArr.length > 20)
      errorHandler("Thầy (Cô), Chỉ được chọn tối đa 20 lớp");
    const options = [
      { key: "1", text: "Tháng 1", value: "1" },
      { key: "2", text: "Tháng 2", value: "2" },
      { key: "3", text: "Tháng 3", value: "3" },
      { key: "4", text: "Tháng 4", value: "4" },
      { key: "5", text: "Tháng 5", value: "5" },
      { key: "6", text: "Tháng 6", value: "6" },
      { key: "7", text: "Tháng 7", value: "7" },
      { key: "8", text: "Tháng 8", value: "8" },
      { key: "9", text: "Tháng 9", value: "9" },
      { key: "10", text: "Tháng 10", value: "10" },
      { key: "11", text: "Tháng 11", value: "11" },
      { key: "12", text: "Tháng 12", value: "12" },
    ];
    return (
      <div>
        <Modal open size="fullscreen">
          <Header className="form-header">
            <Header.Content>{_.get(modalName, generalType)}</Header.Content>
            <Header.Subheader style={{ color: "red" }}>
              <Form>
                <Form.Group inline>
                  <Label>Nguyên tắc tính tuổi:</Label>
                  <Form.Field>
                    <Radio
                      label="Theo Bộ Giáo dục (chỉ tính tháng)"
                      name="radioGroup"
                      checked={!useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Theo Viện Dinh dưỡng (dựa trên ngày cân đo)"
                      name="radioGroup"
                      checked={useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                </Form.Group>
                {generalType === "tdsk" ? (
                  <div style={{ display: "flex" }}>
                    <Label style={{ alignSelf: "center" }}>
                      Tháng cân đo (cho lớp từ 24 tháng tuổi):{" "}
                    </Label>
                    <div style={{ width: 350 }}>
                      <Dropdown
                        placeholder="Chọn tháng cân đo..."
                        fluid
                        multiple
                        selection
                        options={options}
                        onChange={(e, { value }) => {
                          if (value.length === 3) {
                            this.setState({ monthsOfMamChoiLa: value });
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {generalType === "clgd" ? (
                  <>
                    Tháng tổng hợp:
                    <Dropdown
                      key="month"
                      style={{ marginLeft: 10 }}
                      floating
                      inline
                      scrolling
                      options={monthsOptions}
                      value={month}
                      onChange={(event, { value }) => {
                        this.setState({ month: value });
                        handleMonth(value);
                      }}
                      placeholder="Chọn tháng thực hiện cân đo"
                    />
                  </>
                ) : null}
                <Button
                  type="button"
                  style={{ float: "right", marginTop: -50 }}
                  onClick={closeHandler}
                >
                  <Icon name="angle left" /> Quay lại
                </Button>
              </Form>
            </Header.Subheader>
          </Header>
          <Modal.Content as="h3">
            {generalType === "thsk" ? renderTongHopSucKhoe() : null}
            {generalType === "tdsk" ? renderTheoDoiSucKhoe() : null}
            {generalType === "tdsdd" ? renderTheoDoiSDD() : null}
            {generalType === "clgd" && month ? renderEduQuality() : null}
          </Modal.Content>
          <Modal.Actions>{renderButtons()}</Modal.Actions>
        </Modal>
      </div>
    );
  }
}
General.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  successHandler: PropTypes.func.isRequired,
  classes: PropTypes.arrayOf(PropTypes.object).isRequired,
  solieucando: PropTypes.objectOf(PropTypes.object).isRequired,
  generalType: PropTypes.string.isRequired,
  monthsStatus: PropTypes.objectOf(PropTypes.object).isRequired,
  unit: PropTypes.object.isRequired,
  allowEdit: PropTypes.bool,
};

General.defaultProps = {
  allowEdit: false,
};
export default General;
