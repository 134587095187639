import _ from "lodash";
import { YEAR_FETCH, SIGN_OUT } from "../actions/constants";

const initialState = {};

export default function year(state = initialState, action) {
  switch (action.type) {
    case YEAR_FETCH: {
      const oldState = _.cloneDeep(state);
      Object.entries(oldState).forEach(([y]) => {
        if (y !== action.year) {
          oldState[y] = false;
        }
      });
      return {
        ...oldState,
        [action.year]: action.active,
      };
    }

    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
