/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Table,
  Message,
  Button,
  Popup,
  Input,
  Segment,
  Label,
  Icon,
  Dimmer,
  Loader,
  Modal,
  Form,
  Dropdown,
  Header,
  Menu
} from "semantic-ui-react";
import _ from "lodash";
import axios from "axios";

// components
import SoLieu from "./SoLieu";
import Extractor from "./Extractor";
import Viewer from "./Viewer";
import HealthTrack from "./HealthTrack";
import General from "./General";
// services
import { db } from "../../../libs/firebase";
import { convertTimeStamp } from "../../../libs/time";
import { unitFetchInformation } from "../../../redux/actions/units";
import { RESOURCES } from "../../../libs/config";
import { candoFetch, candoClassFetch } from "../../../redux/actions/cando";


const btoa = require("btoa");

const uuid = require("uuid/v1");

const grades = ["3-6thangtuoi", "6-12thangtuoi", "12-18thangtuoi", "18-24thangtuoi", "24-36thangtuoi", "3-4tuoi", "4-5tuoi", "5-6tuoi"];

const months = [["8", "9", "10", "11", "12"], ["1", "2", "3", "4", "5", "6", "7"]];
class CanDo extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    unitFetchInformation: PropTypes.func.isRequired,
    candoFetch: PropTypes.func.isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        teachers: PropTypes.string,
        classes: PropTypes.object,
        staffs: PropTypes.object,
        admin: PropTypes.string
      }).isRequired
    }).isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      gender: PropTypes.string
    }).isRequired,
    year: PropTypes.object.isRequired,
    cando: PropTypes.object.isRequired,
    allowEdit: PropTypes.bool
  };

  static defaultProps = {
    allowEdit: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      email: "",
      classes: this.convertClasses(this.props.unit.information.classes),
      solieucando: {},
      monthsStatus: {},
      showSoLieu: undefined,
      changed: false,
    }
  }

  componentWillMount() {
    axios.get(`${RESOURCES}/rebot/terms.json`).then(data => {
      this.setState({ terms: data.data });
    });
    axios.get(`${RESOURCES}/rebot/solieucando.json`).then(data => {
      this.setState({ solieucando: data.data });
    }).catch(() => this.props.errorHandler("Tải nội dung số liệu cân đo không thành công."));
    axios.get(`${RESOURCES}/rebot/dantoc.json`).then(data => {
      this.setState({ dantoc: data.data });
    }).catch(() => this.props.errorHandler("Tải danh mục dân tộc không thành công."));

    this.unitRef = db.ref(`eduunits/${this.props.unit.unitID}`);
    this.unitRef.on("value", data => {
      if (data !== null && data.val() !== null) {
        const tmp = data.val();
        if (this.props.unit.information.admin !== undefined)
          tmp.admin = this.props.unit.information.admin;
        if (this.props.unit.information.staffs !== undefined && _.keys(this.props.unit.information.staffs).length === 0)
          tmp.staffs = {};
        this.props.unitFetchInformation(this.props.unit.unitID, tmp);
      }
    }, (error) => {
      if (error)
        this.props.errorHandler("Không lấy được thông tin lớp và nhân sự");
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unit.activeYear !== this.props.unit.activeYear) {
      this.getMonthsStatusForOldYear(this.getSortedClasses());
    }
  }

  componentWillUnmount() {
    if (this.unitRef !== undefined)
      this.unitRef.off();
    if (this.monthsStatus !== undefined) {
      _.keys(this.monthsStatus).forEach(classID => this.monthsStatus[classID].off());
      this.monthsStatus = undefined;
    }
  }

  getMonthsStatus = (classes) => {
    if (this.monthsStatus === undefined)
      this.monthsStatus = {};
    classes.forEach(classInfo => {
      if (this.monthsStatus[classInfo.classID] === undefined) {
        this.monthsStatus[classInfo.classID] = db.ref(`cando/${this.props.unit.unitID}/${this.props.unit.activeYear}/${classInfo.classID}/status`);
        this.monthsStatus[classInfo.classID].on("value", snapshot => {
          if (snapshot !== null && snapshot.val() !== null)
            this.setState({
              monthsStatus: {
                ...this.state.monthsStatus,
                [classInfo.classID]: snapshot.val()
              }
            });
          else
            this.setState({
              monthsStatus: {
                ...this.state.monthsStatus,
                [classInfo.classID]: {}
              }
            });
        });
      }
    });
  }

  getMonthsStatusForOldYear = async (classes) => {
    const { unit } = this.props;
    const newMonthsStatus = {};
    if (this.monthsStatus === undefined) this.monthsStatus = {};
    await Promise.all(
      classes.map(async (classInfo) => {
        const ref = db.ref(
          `cando/${unit.unitID}/${unit.activeYear}/${classInfo.classID}/status`
        );
        this.monthsStatus[classInfo.classID] = ref;
        const snapshot = await ref.once("value");
        if (snapshot !== null && snapshot.val() !== null) {
          newMonthsStatus[classInfo.classID] = snapshot.val();
        } else {
          newMonthsStatus[classInfo.classID] = {};
        }
      })
    );
    this.setState({ monthsStatus: newMonthsStatus });
  }
  

  getTeachers = () => (this.props.unit.information.teachers !== undefined) ? JSON.parse(this.props.unit.information.teachers) : []

  getSortedClasses = () => {

    if (this.state.classes === undefined)
      return [];
    const converted = _.map(_.keys(this.state.classes), classID => ({
      ...this.state.classes[classID],
      classID,
      gradeIndex: _.indexOf(grades, this.state.classes[classID].grade[0]),
    }));
    return _.sortBy(_.filter(converted, classItem => this.props.allowEdit || (_.indexOf(classItem.teachers, this.props.user.email) !== -1)), ["gradeIndex", "name"]);
  }

  getUniqID = () => {
    const id = uuid();
    if (this.state.classes[id] === undefined)
      return id;
    return this.getUniqID();
  }

  getStatus = (classID, month) => {
    if (this.state.monthsStatus[classID][month] === undefined)
      return "Chưa có số liệu";
    return `${this.state.monthsStatus[classID][month].modifiedBy}. ${convertTimeStamp(this.state.monthsStatus[classID][month].modifiedTime)}`;
  }

  getPreviousMonth = (classID, currentMonth) => {
    const monthList = _.concat(months[0], months[1]);

    let passed = false;
    const prev = [];
    const rest = [];
    monthList.forEach(month => {
      if (month === currentMonth)
        passed = true;
      else if (passed)
        rest.push(parseFloat(month));
      else
        prev.push(parseFloat(month));
    });
    const foundPrev = _.findLast(prev, month => _.get(this.state.monthsStatus[classID], `${month}.count`, 0));
    if (foundPrev !== undefined)
      return foundPrev;
    const foundRest = _.find(rest, month => _.get(this.state.monthsStatus[classID], `${month}.count`, 0));
    if (foundRest !== undefined)
      return foundRest;
    return undefined;
  }

  addClassHandler = () => {
    const newID = this.getUniqID();
    this.setState({
      classEditor: {
        classID: newID,
        name: "",
        grade: []
      }
    });
  }

  convertClasses = (classes, reverse = false) => {
    if (classes === undefined)
      return {};
    const converted = {};
    _.keys(classes).forEach(classID => {
      let teachers = reverse ? JSON.stringify(classes[classID].teachers) : JSON.parse((classes[classID].teachers === "") ? "{}" : classes[classID].teachers);
      if (reverse && teachers === undefined)
        teachers = JSON.stringify({});
      converted[classID] = {
        ...classes[classID],
        teachers
      };
    });
    return converted;
  }

  readDoTuoi = (dotuoi) => {
    switch (dotuoi) {
      case "3-4tuoi":
        return "3 - 4 tuổi";
      case "4-5tuoi":
        return "4 - 5 tuổi";
      case "5-6tuoi":
        return "5 - 6 tuổi";
      case "3-6thangtuoi":
        return "3 - 6 tháng tuổi";
      case "6-12thangtuoi":
        return "6 - 12 tháng tuổi";
      case "12-18thangtuoi":
        return "12 - 18 tháng tuổi";
      case "18-24thangtuoi":
        return "18 - 24 tháng tuổi";
      case "24-36thangtuoi":
        return "24 - 36 tháng tuổi";
      default:
        return dotuoi;
    }
  }

  addCertificates = (teachers) => {
    teachers.forEach(email => {
      db.ref(`certificate/cando/${btoa(email)}/eduunits`).update({
        [this.props.unit.unitID]: true
      });
    });
  }

  updateTeachers = (teachers) => {
    this.setState({ isWaiting: true });
    db.ref(`eduunits/${this.props.unit.unitID}/teachers`).set(JSON.stringify(teachers), (error) => {
      this.setState({ isWaiting: false });
      if (error)
        this.props.errorHandler("Cập nhật danh sách nhân sự không thành công.");
      else {
        this.addCertificates(teachers);
        this.setState({ email: "" });
      }
    });
  }

  addEmailHandler = () => {
    if (this.state.email === "" || !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))(\.*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email))) {
      this.props.errorHandler("Địa chỉ Email không hợp lệ.");
      return;
    }
    this.updateTeachers(_.uniq(_.concat(this.getTeachers(), [this.state.email])));
  }

  computeYear = (month) => {
    if (month >= 9)
      return parseFloat(this.props.unit.activeYear);
    return parseFloat(this.props.unit.activeYear) + 1;
  }

  saveHandler = () => {
    this.setState({ isWaiting: true });
    db.ref(`eduunits/${this.props.unit.unitID}/classes`).set(this.convertClasses(this.state.classes, true), (error) => {
      this.setState({ isWaiting: false, changed: false });
      if (error)
        this.props.errorHandler("Cập nhật danh sách lớp không thành công.");
      else
        this.props.successHandler("Cập nhật danh sách lớp thành công");
    });
  }

  removeTeacherHandler = (teacher) => {
    const { classes, changed } = this.state;
    const { errorHandler } = this.props;
    const isTeacherInClass = Object.values(classes).some(({ teachers }) => _.includes(teachers, teacher));
    if(isTeacherInClass) {
      errorHandler('Vui lòng xoá giáo viên này ra khỏi lớp trước!');
    } else {
      this.updateTeachers(_.difference(this.getTeachers(), [teacher]));
      if(changed) this.saveHandler();
    }
  }

  validateInformation = (name, grade) => {
    if (name === "") {
      this.props.errorHandler("Tên không được bỏ trống");
      return false;
    }
    if (!grade || !grade.length) {
      this.props.errorHandler("Khối không được bỏ trống");
      return false;
    }
    return true;
  }

  saveClassHandler = () => {
    if (this.state.classEditor === undefined)
      return;
    const { classID, name, grade, teachers } = this.state.classEditor;
    if (!this.validateInformation(name, grade))
      return;
    this.setState({
      classes: {
        ...this.state.classes,
        [classID]: {
          name,
          grade,
          teachers
        }
      },
      classEditor: undefined
    });
  }

  yearHandler = (year) => {
    const {
      cando,
      candoFetch: candoHandler,
      errorHandler,
      unit
    } = this.props;
    if (year === unit.activeYear)
      return;
    if (cando[year]) {
      this.setState({ showYear: year });
    } else {
      this.setState({ isWaiting: true });
      axios.get(`${RESOURCES}/rebot/backup/${unit.information.sgd}/${year}/${unit.unitID}/cando/meta.json`).then(data => {
        if (_.keys(data.data).length) {
          candoHandler(year, data.data);
          this.setState({
            isWaiting: false,
            showYear: year
          });
        } else {
          this.setState({ isWaiting: false });
          errorHandler("Không tìm thấy dữ liệu của năm đã chọn.");
        }
      }).catch(() => {
        this.setState({ isWaiting: false });
        errorHandler("Không tìm thấy dữ liệu của năm đã chọn.");
      });
    }
  }

  renderClassEditor = () => {
    if (this.state.classEditor === undefined)
      return null;
    return (
      <Modal className="custom" open closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ classEditor: undefined })} />}>
        <Header className="form-header">Cấu hình lớp</Header>
        <Modal.Content scrolling style={{ height: 400 }}>
          <Form>
            <Form.Field required>
              <label>Tên lớp</label>
              <Input placeholder="Nhập tên lớp" value={this.state.classEditor.name} onChange={(event, { value }) => this.setState({ classEditor: { ...this.state.classEditor, name: value } })} />
            </Form.Field>
            <Form.Field required>
              <label>Khối</label>
              <Dropdown
                search
                selection
                multiple
                onChange={(event, { value }) => this.setState({ classEditor: { ...this.state.classEditor, grade: value } })}
                options={grades.map(grade => ({
                  key: grade,
                  text: this.readDoTuoi(grade),
                  value: grade
                }))}
                value={this.state.classEditor.grade}
                placeholder="Chọn khối/độ tuổi"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            size="small"
            onClick={() => {
              const newClasses = this.state.classes;
              delete newClasses[this.state.classEditor.classID];
              this.setState({ classes: newClasses, classEditor: undefined });
            }}
          >
            <Icon name="trash" />
            {' '}
            Xoá
          </Button>
          <Button color="green" onClick={this.saveClassHandler}>
            <Icon name="checkmark" />
            {' '}
            Sửa
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  renderMonths = (classInfo, monthList) => monthList.map(month => {
    const exists = this.state.monthsStatus[classInfo.classID][month] !== undefined && this.state.monthsStatus[classInfo.classID][month].count !== undefined && parseFloat(this.state.monthsStatus[classInfo.classID][month].count) > 0;
    let color = exists ? "blue" : "green";
    if (exists && this.state.monthsStatus[classInfo.classID][month].unfilled !== undefined && parseFloat(this.state.monthsStatus[classInfo.classID][month].unfilled) > 0)
      color = "brown";
    return (
      <Popup
        key={month}
        trigger={(
          <Label
            style={{ minWidth: 95 }}
            key={month}
            as="a"
            color={color}
            onClick={() => this.setState({
              showSoLieu: {
                ...classInfo,
                year: this.computeYear(month),
                month,
                day: _.get(this.state.monthsStatus[classInfo.classID][month], "day", 0),
                previousMonth: this.getPreviousMonth(classInfo.classID, month)
              }
            })}
          >
            <Icon name={exists ? "edit" : "add"} />
            {' '}
            Tháng
            {month}
          </Label>
        )}
        content={this.getStatus(classInfo.classID, month)}
      />
    );
  })

  renderClass = (classInfo, index) => {
    const style = (index % 2 === 0) ? { background: "beige" } : {};

    if (_.get(this.props.unit.information, `classes.${classInfo.classID}`, undefined) === undefined)
      return (
        <Table.Row key={classInfo.classID} style={style}>
          {this.props.allowEdit ? (
            <Table.Cell collapsing>
              <Icon
                color="blue"
                name="edit"
                onClick={() => this.setState({
                  classEditor: {
                    classID: classInfo.classID,
                    name: classInfo.name,
                    grade: classInfo.grade,
                    teachers: classInfo.teachers
                  }
                })}
              />
            </Table.Cell>
          ) : null}
          <Table.Cell collapsing>{classInfo.name}</Table.Cell>
          <Table.Cell collapsing>{_.join(classInfo.grade.map(grade => this.readDoTuoi(grade)), ", ")}</Table.Cell>
          {this.props.allowEdit ? (
            <Table.Cell collapsing>
              <Dropdown
                disabled={!this.props.allowEdit}
                selection
                multiple
                value={_.isArray(classInfo.teachers) ? classInfo.teachers : []}
                onChange={(event, { value }) => {
                  this.setState({
                    classes: {
                      ...this.state.classes,
                      [classInfo.classID]: {
                        ...classInfo,
                        teachers: value
                      }
                    }
                  })
                }}
                options={this.getTeachers().map(email => ({ key: email, text: email, value: email }))}
                placeholder="Chọn giáo viên phụ trách"
              />
            </Table.Cell>
          ) : null}
          <Table.Cell>Cần phải lưu thông tin lớp để có thể thêm dữ liệu cân đo</Table.Cell>
        </Table.Row>
      );
    const firstRow = (
      <Table.Row key={classInfo.classID} style={style}>
        {this.props.allowEdit ? (
          <Table.Cell collapsing rowSpan="2">
            <Icon
              color="blue"
              name="edit"
              onClick={() => this.setState({
                classEditor: {
                  classID: classInfo.classID,
                  name: classInfo.name,
                  grade: classInfo.grade,
                  teachers: classInfo.teachers
                }
              })}
            />
          </Table.Cell>
        ) : null}
        <Table.Cell collapsing rowSpan="2">{classInfo.name}</Table.Cell>
        <Table.Cell collapsing rowSpan="2">{_.join(classInfo.grade.map(grade => this.readDoTuoi(grade)), ", ")}</Table.Cell>
        {this.props.allowEdit ? (
          <Table.Cell collapsing rowSpan="2">
            <Dropdown
              disabled={!this.props.allowEdit}
              selection
              multiple
              value={_.isArray(classInfo.teachers) ? classInfo.teachers : []}
              onChange={(event, { value }) => {
                this.setState({
                  classes: {
                    ...this.state.classes,
                    [classInfo.classID]: {
                      ...classInfo,
                      teachers: value
                    }
                  },
                  changed: true,
                })
              }}
              options={this.getTeachers().map(email => ({ key: email, text: email, value: email }))}
              placeholder="Chọn giáo viên phụ trách"
            />
          </Table.Cell>
        ) : null}
        {(this.state.monthsStatus[classInfo.classID] === undefined) ?
          <Table.Cell collapsing rowSpan="2">Đang tải dữ liệu</Table.Cell>
          :
          <Table.Cell collapsing>{this.renderMonths(classInfo, months[0])}</Table.Cell>}
      </Table.Row>
    );
    const secondRow = (
      <Table.Row key={`${classInfo.classID}_2`} style={style}>
        {(this.state.monthsStatus[classInfo.classID] !== undefined) ?
          <Table.Cell collapsing>{this.renderMonths(classInfo, months[1])}</Table.Cell> : null}
      </Table.Row>
    );
    return [firstRow, secondRow];
  }

  render() {
    const {
      showYear,
      dantoc,
      solieucando,
      healthTrack,
      monthsStatus,
      showGeneral,
      generalType,
      terms,
    } = this.state;

    const {
      year,
      unit,
      successHandler,
      errorHandler,
    } = this.props;

    const classes = this.getSortedClasses();
    this.getMonthsStatus(classes);

    const actions = [];
    const renderStaticList = () => {
      return (
        <div className="xuathongkeWrapper">
          <Dropdown text='Xuất thống kê' className='link item xuathongke'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.setState({ generalType: 'tdsk', showGeneral: true })}>Theo dõi sức khỏe trẻ</Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ generalType: 'thsk', showGeneral: true })}>Tổng hợp sức khỏe trẻ toàn trường</Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ generalType: 'tdsdd', showGeneral: true })}>Theo dõi tình hình suy dinh dưỡng của trẻ</Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ generalType: 'clgd', showGeneral: true })}>Tổng hợp chất lượng CSGD </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )
    }
    if (this.props.allowEdit) {
      actions.push(
        <Input
          key="input"
          style={{ width: 600 }}
          type="text"
          placeholder="Nhập Email của nhân sự để cấp quyền thao tác hệ thống cân đo"
          value={this.state.email}
          onChange={(event, { value }) => this.setState({ email: value })}
        >
          <input />
          <Button color="green" onClick={this.addEmailHandler}>
            <Icon name="add" />
            Thêm
          </Button>
        </Input>
      );
      actions.push(
        <Button.Group key="actions" style={{ position: "fixed", right: 10, zIndex: 1 }}>
          <Button size="small" color="teal" onClick={this.addClassHandler}>
            <Icon name="add" />
            {' '}
            Thêm lớp
          </Button>
          <Button size="small" color="blue" onClick={this.saveHandler}>
            <Icon name="save" />
            {' '}
            Lưu thông tin lớp
          </Button>
        </Button.Group>
      );
      actions.push(
        <Segment key="teachers" style={{ background: "transparent" }}>
          {this.getTeachers().map(teacher => (
            <Label key={teacher} as="a">
              {teacher}
              {' '}
              <Icon name="delete" onClick={() => this.removeTeacherHandler(teacher)} />
            </Label>
          ))}
        </Segment>
      );
    }

    return (
      <div>
        {
          showGeneral ? (
            <General
              allowEdit={this.props.allowEdit}
              unit={unit}
              errorHandler={errorHandler}
              closeHandler={() => this.setState({ showGeneral: false })}
              classes={classes}
              monthsStatus={monthsStatus}
              solieucando={solieucando}
              generalType={generalType}
              dantoc={dantoc}
              terms={terms}
              successHandler={successHandler}
            />
          ) : null
        }
        {healthTrack ? (
          <HealthTrack
            unitName={_.get(unit, 'information.name', '')}
            unitID={unit.unitID}
            activeYear={unit.activeYear}
            errorHandler={errorHandler}
            closeHandler={() => this.setState({ healthTrack: false })}
            classes={classes}
            monthsStatus={monthsStatus}
            solieucando={solieucando}
            unit={unit}
          />
        ) : null}
        {(showYear && showYear !== unit.activeYear) ? (
          <Viewer
            year={showYear}
            closeHandler={() => this.setState({ showYear: undefined })}
            successHandler={successHandler}
            errorHandler={errorHandler}
            solieucando={solieucando}
            dantoc={dantoc}
          />
        ) : null}
        {(this.state.isWaiting || this.state.solieucando.weight === undefined || this.state.solieucando.height === undefined || this.state.solieucando.weightLength === undefined || this.state.solieucando.bmi === undefined) ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        ) : null}
        {(this.state.showSoLieu !== undefined) ? (
          <SoLieu
            classInfo={this.state.showSoLieu}
            unit={this.props.unit}
            closeHandler={() => this.setState({ showSoLieu: undefined })}
            errorHandler={this.props.errorHandler}
            successHandler={this.props.successHandler}
            solieucando={this.state.solieucando}
            dantoc={this.state.dantoc}
            terms={this.state.terms}
            monthsStatus={this.state.monthsStatus}
          />
        ) : null}
        {this.renderClassEditor()}
        {(this.state.showExtractor !== undefined) ? (
          <Extractor
            closeHandler={() => this.setState({ showExtractor: undefined })}
            errorHandler={this.props.errorHandler}
            successHandler={this.props.successHandler}
            classes={this.state.showExtractor.classes}
            monthsStatus={this.state.showExtractor.monthsStatus}
            unit={this.props.unit}
            solieucando={this.state.solieucando}
          />
        ) : null}
        {(_.keys(year).length > 1) ? (
          <Menu
            compact
            secondary
            color="brown"
            style={{ display: "contents" }}
          >
            {_.map(year, (active, key) => (
              <Menu.Item
                style={{ float: "left" }}
                active={active}
                key={key}
                onClick={() => this.yearHandler(key)}
              >
                Năm học
                {' '}
                {key}
              </Menu.Item>
            ))}
          </Menu>
        ) : null}
        <Message warning>
          <Message.Header>Hướng dẫn</Message.Header>
          <Message.List>
            {this.props.allowEdit ? (
              <>
                <Message.Item>
                  {(this.props.user.gender === "M") ? "Thầy" : "Cô"}
                  {' '}
                  bổ sung danh sách nhân sự bằng cách thêm Email.
                </Message.Item>
                <Message.Item>
                  {(this.props.user.gender === "M") ? "Thầy" : "Cô"}
                  {' '}
                  tạo lớp và chọn những Email nào được quyền cập nhật dữ liệu cân đo cho lớp đó.
                </Message.Item>
              </>
            ) : (
              <Message.Item>
                {`${(this.props.user.gender === "M") ? "Thầy" : "Cô"} bổ sung dữ liệu cân đo vào lớp của mình bằng cách ấn vào tháng tương ứng.`}
              </Message.Item>
            )}
          </Message.List>
          <Button.Group className="right-top-button">
            {renderStaticList()}
            <Button color="orange" size="small" onClick={() => this.setState({ healthTrack: true })}>
              <Icon name="file excel outline" />
              Theo dõi sức khoẻ
            </Button>
            <Button color="brown" icon="calculator" content="Tổng hợp số liệu cân đo" onClick={() => this.setState({ showExtractor: { classes, monthsStatus: this.state.monthsStatus } })} />
          </Button.Group>
        </Message>
        <Message warning>
          <Message.Header>Chú thích màu</Message.Header>
          <Label style={{ marginTop: 5 }} color="green">Chưa có danh sách</Label>
          <Label style={{ marginTop: 5 }} color="brown">Còn bé chưa có số liệu cân đo</Label>
          <Label style={{ marginTop: 5 }} color="blue">Đã có số liệu cân đo cho tất cả các bé</Label>
        </Message>

        {actions}

        <Table style={{ background: "transparent" }} celled striped color="brown" unstackable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="solieuHeader" collapsing colSpan={this.props.allowEdit ? 2 : 1}>Tên lớp</Table.HeaderCell>
              <Table.HeaderCell className="solieuHeader" collapsing>Khối</Table.HeaderCell>
              {this.props.allowEdit ? <Table.HeaderCell className="solieuHeader" collapsing>Nhân sự</Table.HeaderCell> : null}
              <Table.HeaderCell className="solieuHeader" collapsing>Tháng</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {classes.map((classInfo, index) => this.renderClass(classInfo, index))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = ({ user, unit, year, cando }) => ({
  user, unit, year, cando
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  unitFetchInformation,
  candoFetch,
  candoClassFetch
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CanDo);